import React, { useState } from "react"
import axios from "axios"
import { useSnackbar } from "notistack"
import {
  Grid,
  // Snackbar,
  TextField,
  makeStyles,
  FormControl,
  CircularProgress,
} from "@material-ui/core"

// import MuiAlert from "@material-ui/lab/Alert"

import Button from "./Button"
import "../components/layout.css"

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />
// }

const ContactForm = () => {
  const regexMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const phoneRegexd = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
  const [contactValues, setContactValues] = useState({
    name: "",
    email: "",
    message: "",
    telephone: "",
    messageSent: "",
  })
  const [errorMassege, setErrorMassege] = useState({
    name: false,
    nameText: "",
    email: false,
    emailText: "",
    telephone: false,
    telephoneText: "",
    message: false,
    messageText: "",
    responseError: "",
  })
  const classes = useStyles()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [loading, setLoading] = useState(false)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  // const [loadedBooks, setLoadedProperties] = React.useState(12)
  // const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false)

  // const handleOpenSnackbar = () => {
  //   setOpenSuccessSnackbar(true)
  //   setTimeout(() => {
  //     setOpenSuccessSnackbar(false)
  //   }, 3000)
  // }

  // const handleCloseAddToCard = () => {
  //   setOpenSuccessSnackbar(false)
  // }

  let variant

  const enterdUserEmail = () => {
    if (regexMail.test(contactValues.email)) {
      setErrorMassege({ ...errorMassege, email: false, emailText: "" })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        email: true,
        emailText: "You have entered an invalid E-mail address!",
      })
      // variant = "error"
      // enqueueSnackbar("You have entered an invalid E-mail address!", { variant })
      return false
    }
  }

  const enterdUserTelephone = () => {
    if (phoneRegexd.test(contactValues.telephone)) {
      setErrorMassege({ ...errorMassege, telephone: false, telephoneText: "" })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        telephone: true,
        telephoneText: "You have entered an invalid Phone number!",
      })
      // variant = "error"
      // enqueueSnackbar("You have entered an invalid Phone number!", { variant })
      return false
    }
  }

  const enterdUserName = () => {
    if (contactValues.name.length !== 0) {
      setErrorMassege({ ...errorMassege, name: false, nameText: "" })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        name: true,
        nameText: "This field is required!",
      })
      return false
    }
  }

  const enterdUserMessage = () => {
    if (contactValues.message.length !== 0) {
      setErrorMassege({ ...errorMassege, message: false, messageText: "" })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        message: true,
        messageText: "This field is required!",
      })
      return false
    }
  }

  const handleChange = prop => event => {
    if (prop === "email" && isSubmitted) {
      enterdUserEmail()
    }
    if (prop === "telephone" && isSubmitted) {
      enterdUserTelephone()
    }
    if (prop === "name" && isSubmitted) {
      enterdUserName()
    }
    if (prop === "message" && isSubmitted) {
      enterdUserMessage()
    }
    setContactValues({ ...contactValues, [prop]: event.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    setDisabledBtn(true)
    setIsSubmitted(true)

    if (enterdUserEmail() === false) {
      variant = "error"
      enqueueSnackbar("You have entered an invalid E-mail address!", {
        variant,
      })
    } else if (enterdUserTelephone() === false) {
      variant = "error"
      enqueueSnackbar("You have entered an invalid Phone number!", { variant })
    } else if (enterdUserName() === false) {
      variant = "error"
      enqueueSnackbar("This field is required!", { variant })
    } else if (enterdUserMessage() === false) {
      variant = "error"
      enqueueSnackbar("This field is required!", { variant })
    } else {
      if (
        enterdUserEmail() === false &&
        enterdUserTelephone() === false &&
        enterdUserName() === false &&
        enterdUserMessage() === false
      ) {
        variant = "error"
        enqueueSnackbar("Please check all the fiels and try Again!", {
          variant,
        })
      }
    }
    if (
      enterdUserEmail() &&
      enterdUserTelephone() &&
      enterdUserName() &&
      enterdUserMessage()
    ) {
      axios
        .post(`https://cms.trustit-co.com/api/content/arafasapps/messages/`, {
          email: {
            iv: contactValues.email,
          },
          name: {
            iv: contactValues.name,
          },
          telephone: {
            iv: contactValues.telephone,
          },
          message: {
            iv: contactValues.message,
          },
        })
        .then(() => {
          variant = "success"
          enqueueSnackbar("Your email sent successfully.", { variant })
          setTimeout(() => {
            setLoading(false)
            setContactValues({
              ...contactValues,
              name: "",
              email: "",
              message: "",
              telephone: "",
            })
            setDisabledBtn(false)
          }, 800)
        })
        .catch(() => {
          variant = "error"
          enqueueSnackbar("Something whent wrong. please try again later.", {
            variant,
          })
          setTimeout(() => {
            setLoading(false)
            setDisabledBtn(false)
          }, 800)
        })
    } else {
      setTimeout(() => {
        setLoading(false)
        setDisabledBtn(false)
      }, 800)
    }
  }

  return (
    <div className="ContactForm">
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <FormControl
              fullWidth
              className={`${classes.marginForForms} ${classes.formsLabel}`}
            >
              <TextField
                size="small"
                id="email"
                type="email"
                label="email*"
                variant="outlined"
                value={contactValues.email && contactValues.email}
                onChange={handleChange("email")}
                error={errorMassege.email}
                helperText={errorMassege.emailText}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-end">
          <Grid item md={12} sm={12} xs={12}>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup} ${classes.formsLabel}`}
            >
              <TextField
                size="small"
                id="telephone"
                type="text"
                label="Telephone*"
                variant="outlined"
                value={contactValues.telephone}
                onChange={handleChange("telephone")}
                error={errorMassege.telephone}
                helperText={errorMassege.telephoneText}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-end">
          <Grid item md={12} sm={12} xs={12}>
            <FormControl
              fullWidth
              className={`${classes.marginForForms} ${classes.formsLabel}`}
            >
              <TextField
                size="small"
                id="name"
                name="name"
                label="Name*"
                type="name"
                variant="outlined"
                value={contactValues.name}
                onChange={handleChange("name")}
                className={classes.marginForForms}
                error={errorMassege.name}
                helperText={errorMassege.nameText}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-end">
          <Grid item md={12} sm={12} xs={12}>
            <FormControl
              fullWidth
              className={`${classes.marginForForms} ${classes.formsLabel}`}
            >
              <TextField
                size="small"
                id="message"
                label="Message*"
                multiline
                rows={4}
                variant="outlined"
                value={contactValues.message}
                onChange={handleChange("message")}
                className={classes.marginForForms}
                error={errorMassege.message}
                helperText={errorMassege.messageText}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justify="space-between"
          className={classes.marginForForms}
        >
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={disabledBtn}
              className={`${classes.sendBtn} button-main`}
            >
              {loading && <CircularProgress size={16} />}
              {!loading && "Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default ContactForm

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginForForms: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  sendBtn: {
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
}))
