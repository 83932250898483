import React from "react"
// import styled from "styled-components"
import { Button, styled } from "@material-ui/core"

// const StyledButton = withStyles(`
//     marginTop: 8,
//     color: "#fff",
//     borderWidth: 0,
//     borderRadius: 5,
//     transition: "0.3s",
//     fontWeight: "bold",
//     padding: "12px 24px 10px",
//     boxShadow: "2px 2px 5px 0px rgb(0 0 0 / 20%)",
//     backgroundImage: "linear-gradient(248deg, #00a69c 0%, #29b473 91%)",
//     "& .MuiButton-label": {
//       lineHeight: 0.7,
//     },
//     "&:hover": {
//       borderRadius: 10,
//       transition: "0.3s",
//       backgroundImage: "linear-gradient(248deg, #29b473 0%, #00a69c 91%)",
//     },
//   `)

// const CustomButton = ({ Childern }) => {
//   return <StyledButton>{Childern}</StyledButton>
// }

const StyledButton = styled(Button)({
  height: 30,
  marginTop: 8,
  color: "#fff",
  minWidth: 100,
  borderWidth: 0,
  borderRadius: 5,
  transition: "0.3s",
  fontWeight: "bold",
  padding: "12px 24px 10px",
  boxShadow: "2px 2px 5px 0px rgb(0 0 0 / 20%)",
  backgroundImage: "linear-gradient(248deg, #00a69c 0%, #29b473 91%)",
  "& .MuiButton-label": {
    lineHeight: 0.7,
  },
  "&:hover": {
    borderRadius: 10,
    transition: "0.3s",
    backgroundImage: "linear-gradient(248deg, #29b473 0%, #00a69c 91%)",
  },
})

export default function StyledComponents({
  children,
  newStyle,
  onClick,
  variant,
  disabled,
  type,
  loading,
  className
}) {
  return (
    <StyledButton
      style={newStyle && newStyle}
      className={className && className}
      onClick={onClick && onClick}
      variant={variant && variant}
      disabled={disabled && disabled}
      type={type && type}
      loading={loading && loading}
    >
      {children}
    </StyledButton>
  )
}

// export default CustomButton
