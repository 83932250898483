import React, { useEffect, useState } from "react"
import {
  Grid,
  Dialog,
  Container,
  Typography,
  makeStyles,
  DialogTitle,
  DialogContent,
} from "@material-ui/core"

import lottie from "lottie-web"
import {detect} from "detect-browser"
import * as Scroll from "react-scroll"

import ContactForm from "./contactForm"

import logo from "../../static/images/logo500.png"
import * as goToTop from "../../static/icons/arrow-up.json"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

export default function Footer({ contactFormStatus, setContactFormToggle }) {
  const classes = useStyles()
  const browser = detect()
  const scroll = Scroll.animateScroll
  const [hideArrow, setHideArrow] = useState(false)
  const [toggleDialog, setToggleDialog] = useState(false)

  const handleClose = () => {
    setContactFormToggle(!contactFormStatus)
  }
  const handleGoToTop = () => {
    windowGlobal.scrollTo(0, 0)
  }
  const scrollToTop = () => {
    scroll.scrollToTop()
  }
  
  useEffect(() => {
    setToggleDialog(contactFormStatus)
  }, [contactFormStatus])

  useEffect(() => {
    const handleScroll = () => {
      if (windowGlobal.pageYOffset >= 265) {
        setHideArrow(true)
      } else {
        setHideArrow(false)
      }
    }
    windowGlobal.addEventListener("scroll", handleScroll)
  }, [windowGlobal.pageYOffset])

  useEffect(() => {
    lottie.loadAnimation({
      container: documentGlobal.querySelector("#react-logo"),
      animationData: goToTop,
    })
  }, [])

  // handle the case where we don't detect the browser
  // if (browser) {
  //   console.log(browser.name)
  //   console.log(browser.version)
  //   console.log(browser.os)
  // }

  return (
    <Container
      maxWidth={false}
      className={`${classes.fullWidthContainer} ${classes.footerBg}`}
    >
      <Container>
        <Grid container>
          <Grid item sm={6} className={classes.footerLogo}>
            <img src={logo} alt="logo" className={classes.footerLogo} />
          </Grid>
          <Grid item sm={6} className={classes.footerAddress}>
            <Typography>Istanbul, Turkey,</Typography>
            <Typography>
              Ataşehir, Küçükbakkalköy, Dudullu Cd., 34750,
            </Typography>
            <Typography>Brandium Residence, R2 Block, No: 198</Typography>
            <Typography>Phone: +90 (530) 345 31 53</Typography>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={toggleDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography className={classes.sectionTitle}>
            Let’s start a Project togther
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.contentContainer}>
          <div style={{ width: 800, maxWidth: "100%" }}>
            <ContactForm />
          </div>
        </DialogContent>
      </Dialog>

      <div
        className={`${classes.goToTpButton} ${
          hideArrow ? classes.fadeIn : classes.fadeOut
        }`}
        onClick={
          browser.name !== "safari" ? handleGoToTop : () => scrollToTop()
        }
      >
        <div id="react-logo" />
      </div>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  fullWidthContainer: {
    maxWidth: "100%",
    padding: 0,
    margin: 0,
  },
  footerBg: {
    backgroundColor: "#1a1c20",
    "& .MuiGrid-container": {
      justifyContent: "space-between",
    },
  },
  footerLogo: {
    maxWidth: 180,
    margin: "10px 0 0",
  },
  footerAddress: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    "& p": {
      color: "#fff",
    },
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: "1.7rem",
    textAlign: "center",
  },
  contentContainer: {
    // width: 800,
    // maxWidth: "98%",
  },
  goToTpButton: {
    position: "fixed",
    bottom: 10,
    right: 10,
    zIndex: 99,
    "& > div": {
      width: 64,
      height: 64,
    },
  },
  fadeOut: {
    opacity: 0,
    width: 0,
    height: 0,
    transition: "width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s",
  },
  fadeIn: {
    opacity: 1,
    // width: 100,
    // height: 100,
    transition: "width 0.5s, height 0.5s, opacity 0.5s 0.5s",
  },
}))
